import React, { useCallback, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { css, cx } from 'emotion';
import moment from 'moment';
import parse from 'html-react-parser';
import { Typography } from '@material-ui/core';
import {
  benefitContainer,
  itemContainer,
  largeScreen,
  listContainer,
  smallScreen,
} from '../additional-benefits/product-components/shared-styles';
import {
  ChartLineUp,
  CreditCard,
  CurrencyCircleDollar,
  HourglassSimpleMedium,
  IdentificationCard,
  Receipt,
  Vault,
  X,
} from 'phosphor-react';
import { posthogAttrs } from '../../../posthog-constants';
import { Button, Modal, Text } from '../../../shared-components';
import { flex, scrollbar } from '../../../shared-components/shared-styles';
import { colors } from '../../../shared-components/styles';
import { CarrierLogo } from '../../brite-viewer/components/benefits/carrier-logo';
import { useStore } from '../../store-provider/use-store';
import errorHandler from '../../../lib/stackdriver/errorHandler';

const FED_AMOUNT_SINGLE = 4300;
const FED_AMOUNT = 8550;

const cardContainer = css`
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 16px;
  margin: 8px 0;
  flex-grow: 1;
  p {
    margin-left: 16px;
  }
  svg {
    min-width: 32px;
  }
  ${smallScreen} {
    flex-direction: column;
    svg {
      text-align: center;
    }
  }
`;

export const HSAPlanViewer = (props) => {
  const store = useStore();

  const [learnMoreOpen, setLearnMoreOpen] = useState(false);
  const [matchContribution, setMatchContribution] = useState(0);

  const titleDescriptions = useMemo(() => {
    if (props.benefit && props.benefit.TitleDescriptions) {
      return props.benefit.TitleDescriptions.filter(
        (item) => !item.Autogenerated && !!item.Title && !!item.Description
      );
    }
    return [];
  }, []);

  const getMonthsRemaining = (rec) => {
    if (rec.Type === 'newhire' && rec.Plan.EffectiveDate) {
      let now = moment();
      let effectiveDate = moment(rec.Plan.EffectiveDate);
      if (now.month() === effectiveDate.month()) {
        return 12;
      }

      if (now.isBefore(effectiveDate)) {
        return 12;
      }
      let monthsLeft = 12;

      if (now.year() > effectiveDate.year()) {
        monthsLeft = effectiveDate.month() - now.month();
      } else {
        let a = effectiveDate.month() - now.month();

        monthsLeft = 12 - Math.abs(a);
      }

      return monthsLeft;
    }
    return 12;
  };

  const deprecatedGetEmployerMatchHSAValue = (rec) => {
    errorHandler.report('called deprecatedGetEmployerMatchHSAValue');
    if (rec.Plan.HSAContributionType === 'Employer Match' || rec.Plan.HSAContributionType === 'HSA - Employer Match') {
      if (props.answers.Enrolling.Family) {
        return rec.Plan.HSAContributions.FamilyEmployerMonthlyMatch;
      } else if (props.answers.Enrolling.EmployeeOnly) {
        return rec.Plan.HSAContributions.EmployeeOnlyEmployerMonthlyMatch;
      } else if (props.answers.Enrolling.EmployeePlusChildren) {
        return rec.Plan.HSAContributions.EmployeeChildrenEmployerMonthlyMatch;
      } else if (props.answers.Enrolling.EmployeePlusSpouse) {
        return rec.Plan.HSAContributions.EmployeeSpouseEmployerMonthlyMatch;
      }
    }
    return 0;
  };

  const deprecatedGetMaxHSAValue = (rec) => {
    errorHandler.report('called deprecatedGetMaxHSAValue');
    if (rec.Plan.HSAContributionType === 'Employer Match' || rec.Plan.HSAContributionType === 'HSA - Employer Match') {
      if (props.answers.Enrolling.Family) {
        return rec.Plan.HSAContributions.FamilyEmployerMonthlyMatchMax;
      } else if (props.answers.Enrolling.EmployeeOnly) {
        return rec.Plan.HSAContributions.EmployeeOnlyEmployerMonthlyMatchMax;
      } else if (props.answers.Enrolling.EmployeePlusChildren) {
        return rec.Plan.HSAContributions.EmployeeChildrenEmployerMonthlyMatchMax;
      } else if (props.answers.Enrolling.EmployeePlusSpouse) {
        return rec.Plan.HSAContributions.EmployeeSpouseEmployerMonthlyMatchMax;
      }
    }
  };

  const getEmployerMatchHSAValue = (rec, hsaPlanProgram, isEmployerMatch) => {
    try {
      if (isEmployerMatch) {
        if (hsaPlanProgram?.RawData?.Details) {
          if (props.answers.Enrolling.Family) {
            return hsaPlanProgram.RawData.Details.FamilyEmployerMonthlyMatch;
          } else if (props.answers.Enrolling.EmployeeOnly) {
            return hsaPlanProgram.RawData.Details.EmployeeOnlyEmployerMonthlyMatch;
          } else if (props.answers.Enrolling.EmployeePlusChildren) {
            return hsaPlanProgram.RawData.Details.EmployeeChildrenEmployerMonthlyMatch;
          } else if (props.answers.Enrolling.EmployeePlusSpouse) {
            return hsaPlanProgram.RawData.Details.EmployeeSpouseEmployerMonthlyMatch;
          }
        }
      }
      return 0;
    } catch (e) {
      console.error('Error getting getEmployerMatchHSAValue', e);
    }
    return deprecatedGetEmployerMatchHSAValue(rec);
  };

  const getMaxHSAValue = (rec, hsaPlanProgram, isEmployerMatch) => {
    try {
      if (isEmployerMatch) {
        if (hsaPlanProgram?.RawData?.Details) {
          if (props.answers.Enrolling.Family) {
            return hsaPlanProgram.RawData.Details.FamilyEmployerMonthlyMatchMax;
          } else if (props.answers.Enrolling.EmployeeOnly) {
            return hsaPlanProgram.RawData.Details.EmployeeOnlyEmployerMonthlyMatchMax;
          } else if (props.answers.Enrolling.EmployeePlusChildren) {
            return hsaPlanProgram.RawData.Details.EmployeeChildrenEmployerMonthlyMatchMax;
          } else if (props.answers.Enrolling.EmployeePlusSpouse) {
            return hsaPlanProgram.RawData.Details.EmployeeSpouseEmployerMonthlyMatchMax;
          }
        }
      }
    } catch (e) {
      console.error('Error getting getMaxHSAValue', e);
    }
    return deprecatedGetMaxHSAValue(rec);
  };

  const deprecatedGetAnnualEmployeeAmount = (rec) => {
    errorHandler.report('called deprecatedGetAnnualEmployeeAmount');
    if (props.answers.Enrolling.Family) {
      return rec.Plan.HSAContributions.Family * getMonthsRemaining(rec);
    } else if (props.answers.Enrolling.EmployeeOnly) {
      return rec.Plan.HSAContributions.EmployeeOnly * getMonthsRemaining(rec);
    } else if (props.answers.Enrolling.EmployeePlusChildren) {
      return rec.Plan.HSAContributions.EmployeeChildren * getMonthsRemaining(rec);
    } else if (props.answers.Enrolling.EmployeePlusSpouse) {
      return rec.Plan.HSAContributions.EmployeeSpouse * getMonthsRemaining(rec);
    }
  };

  const getAnnualEmployeeAmount = (rec, hsaPlanProgram) => {
    try {
      if (hsaPlanProgram?.RawData?.Details) {
        if (props.answers.Enrolling.Family) {
          return hsaPlanProgram.RawData.Details.Family * getMonthsRemaining(rec);
        } else if (props.answers.Enrolling.EmployeeOnly) {
          return hsaPlanProgram.RawData.Details.EmployeeOnly * getMonthsRemaining(rec);
        } else if (props.answers.Enrolling.EmployeePlusChildren) {
          return hsaPlanProgram.RawData.Details.EmployeeChildren * getMonthsRemaining(rec);
        } else if (props.answers.Enrolling.EmployeePlusSpouse) {
          return hsaPlanProgram.RawData.Details.EmployeeSpouse * getMonthsRemaining(rec);
        }
      }
    } catch (e) {
      console.error('Error getting getAnnualEmployeeAmount', e);
    }

    return deprecatedGetAnnualEmployeeAmount(rec);
  };

  const deprecatedHSAEmployerValue = (rec) => {
    errorHandler.report('called deprecatedHSAEmployerValue');
    if (
      !rec.Plan.HSACompatible ||
      rec.Plan.HSAContributionType === 'HSA - No Employer Contribution' ||
      rec.Plan.HSAContributionType === 'FSA' ||
      rec.Plan.HSAContributionType === 'none'
    ) {
      return 0;
    }
    if (rec.Plan.HSAContributionType === 'Employer Match' || rec.Plan.HSAContributionType === 'HSA - Employer Match') {
      if (props.answers.Enrolling.Family) {
        return Number(rec.Plan.HSAContributions.FamilyEmployerMonthlyMatchMax) || 0;
      } else if (props.answers.Enrolling.EmployeeOnly) {
        return Number(rec.Plan.HSAContributions.EmployeeOnlyEmployerMonthlyMatchMax) || 0;
      } else if (props.answers.Enrolling.EmployeePlusChildren) {
        return Number(rec.Plan.HSAContributions.EmployeeChildrenEmployerMonthlyMatchMax) || 0;
      } else if (props.answers.Enrolling.EmployeePlusSpouse) {
        return Number(rec.Plan.HSAContributions.EmployeeSpouseEmployerMonthlyMatchMax) || 0;
      }
    }

    if (props.answers.Enrolling.Family) {
      return Number(rec.Plan.HSAContributions.Family) || 0;
    } else if (props.answers.Enrolling.EmployeeOnly) {
      return Number(rec.Plan.HSAContributions.EmployeeOnly) || 0;
    } else if (props.answers.Enrolling.EmployeePlusChildren) {
      return Number(rec.Plan.HSAContributions.EmployeeChildren) || 0;
    } else if (props.answers.Enrolling.EmployeePlusSpouse) {
      return Number(rec.Plan.HSAContributions.EmployeeSpouse) || 0;
    }
    return 0;
  };

  const hsaEmployerValue = (rec, hsaPlanProgram, isEmployerMatch) => {
    try {
      if (hsaPlanProgram?.RawData?.Details) {
        if (hsaPlanProgram?.RawData?.Details?.ContributionType === 'HSA - No Employer Contribution') {
          return 0;
        }

        if (isEmployerMatch) {
          if (props.answers.Enrolling.Family) {
            return Number(hsaPlanProgram.RawData.Details.FamilyEmployerMonthlyMatchMax) || 0;
          } else if (props.answers.Enrolling.EmployeeOnly) {
            return Number(hsaPlanProgram.RawData.Details.EmployeeOnlyEmployerMonthlyMatchMax) || 0;
          } else if (props.answers.Enrolling.EmployeePlusChildren) {
            return Number(hsaPlanProgram.RawData.Details.EmployeeChildrenEmployerMonthlyMatchMax) || 0;
          } else if (props.answers.Enrolling.EmployeePlusSpouse) {
            return Number(hsaPlanProgram.RawData.Details.EmployeeSpouseEmployerMonthlyMatchMax) || 0;
          }
        }

        if (props.answers.Enrolling.Family) {
          return Number(hsaPlanProgram.RawData.Details.Family) || 0;
        } else if (props.answers.Enrolling.EmployeeOnly) {
          return Number(hsaPlanProgram.RawData.Details.EmployeeOnly) || 0;
        } else if (props.answers.Enrolling.EmployeePlusChildren) {
          return Number(hsaPlanProgram.RawData.Details.EmployeeChildren) || 0;
        } else if (props.answers.Enrolling.EmployeePlusSpouse) {
          return Number(hsaPlanProgram.RawData.Details.EmployeeSpouse) || 0;
        }

        return 0;
      }
    } catch (e) {
      console.error('Error getting hsaEmployerValue', e);
    }

    return deprecatedHSAEmployerValue(rec);
  };

  const getCoverBillsValue = (rec, hsaPlanProgram, isEmployerMatch) => {
    if (isEmployerMatch) {
      // the max you can possibly contribute to the hsa for the year
      let mA = getMaxMonthlyValue(rec, hsaPlanProgram, isEmployerMatch);

      // this is the monthly max the employer will match
      let maxEmployerMatch = getMaxHSAValue(rec, hsaPlanProgram, isEmployerMatch);

      // this is how much the employer will give for every $1 you give
      let employerMatch = getEmployerMatchHSAValue(rec, hsaPlanProgram, isEmployerMatch);

      // the minimum you need to contribute to max out employers match
      let minimumMonthly = 0;
      if (employerMatch > 0) {
        minimumMonthly = maxEmployerMatch / employerMatch;
      }

      // if you have costs after max employer match
      let totalCosts =
        rec.PlanCostsForYear?.FinalPlanCostBreakdown?.EstimatedOutOfPocketCost -
        hsaEmployerValue(rec, hsaPlanProgram, isEmployerMatch) * getMonthsRemaining(rec);
      if (totalCosts > 0) {
        let amountLeftToCover = totalCosts;

        // monthly amount left to cover
        const amt = amountLeftToCover / getMonthsRemaining(rec);
        if (amt > mA) {
          return mA;
        }
        if (amt < minimumMonthly) {
          return minimumMonthly;
        }
        return amt;
      } else {
        return minimumMonthly;
      }
    }

    const maxAmount = getMaxMonthlyValue(rec, hsaPlanProgram, isEmployerMatch);
    // medical expenses exist

    let totalCostsAgain =
      rec.PlanCostsForYear?.FinalPlanCostBreakdown?.EstimatedOutOfPocketCost -
      hsaEmployerValue(rec, hsaPlanProgram, isEmployerMatch) * getMonthsRemaining(rec);
    if (totalCostsAgain > 0) {
      let costRemainingAfterEmployerPays = totalCostsAgain;
      if (costRemainingAfterEmployerPays <= 0) {
        return 0;
      } else {
        const amount = costRemainingAfterEmployerPays / getMonthsRemaining(rec);
        if (amount > maxAmount) {
          return maxAmount;
        }
        return amount;
      }
    } else {
      return 0;
    }
  };

  const replaceURLWithHTMLLinks = (text) => {
    var exp = /(?:^|[^"'])((ftp|http|https|file):\/\/[\S]+(\b|$))/gi;
    return text.replace(exp, " <a href='$1' target='_blank'>$1</a>");
  };

  const parseDisclaimer = (disclaimer) => {
    return parse(replaceURLWithHTMLLinks(disclaimer));
  };

  const getMaxMonthlyValue = (rec, hsaPlanProgram, isEmployerMatch) => {
    const getValue = () => {
      if (isEmployerMatch) {
        let maxEmployerMatch = getMaxHSAValue(rec, hsaPlanProgram, isEmployerMatch);
        let employerMatch = getEmployerMatchHSAValue(rec, hsaPlanProgram, isEmployerMatch);
        let yearlyMaxEmployerMatch = maxEmployerMatch * getMonthsRemaining(rec);
        let employeeResponsibleToGetFullMatch = 0;
        if (employerMatch > 0) {
          employeeResponsibleToGetFullMatch = (maxEmployerMatch / employerMatch) * getMonthsRemaining(rec);
        }

        if (props.answers.Enrolling.EmployeeOnly) {
          let leftOverAmountAfterEmployerMatch =
            FED_AMOUNT_SINGLE - (yearlyMaxEmployerMatch + employeeResponsibleToGetFullMatch);
          if (leftOverAmountAfterEmployerMatch > 0) {
            return Math.floor(
              leftOverAmountAfterEmployerMatch / getMonthsRemaining(rec) +
                employeeResponsibleToGetFullMatch / getMonthsRemaining(rec)
            );
          } else {
            return Math.floor(employeeResponsibleToGetFullMatch / getMonthsRemaining(rec));
          }
        } else {
          let leftOverAmountAfterEmployerMatch =
            FED_AMOUNT - (yearlyMaxEmployerMatch + employeeResponsibleToGetFullMatch);
          if (leftOverAmountAfterEmployerMatch > 0) {
            return Math.floor(
              leftOverAmountAfterEmployerMatch / getMonthsRemaining(rec) +
                employeeResponsibleToGetFullMatch / getMonthsRemaining(rec)
            );
          } else {
            return Math.floor(employeeResponsibleToGetFullMatch / getMonthsRemaining(rec));
          }
        }
      }

      if (props.answers.Enrolling.EmployeeOnly) {
        return Math.floor((FED_AMOUNT_SINGLE - getAnnualEmployeeAmount(rec, hsaPlanProgram)) / getMonthsRemaining(rec));
      } else {
        return Math.floor((FED_AMOUNT - getAnnualEmployeeAmount(rec, hsaPlanProgram)) / getMonthsRemaining(rec));
      }
    };

    const value = getValue();
    if (isNaN(value)) {
      return 0;
    }
    return value;
  };

  const selectedRecommendation = props.plan;

  const hsaPlanProgram = useMemo(() => {
    if (selectedRecommendation.Plan?.includedPrograms?.length) {
      const includedProgram = selectedRecommendation.Plan?.includedPrograms.find(({ ProgramType }) => {
        return ProgramType === 'health_savings_account';
      });
      return includedProgram;
    }
    return {};
  }, [selectedRecommendation.Plan?.ID, store?.data?.products?.length]);

  const relatedProduct = useMemo(() => {
    if (hsaPlanProgram) {
      return store?.data?.products?.find(({ ID }) => ID === hsaPlanProgram?.RelatedProductID);
    }
    return {};
  }, [hsaPlanProgram]);

  const deprecatedIsEmployerMatch =
    selectedRecommendation.Plan.HSAContributionType === 'Employer Match' ||
    selectedRecommendation.Plan.HSAContributionType === 'HSA - Employer Match';

  const isEmployerMatch = useMemo(() => {
    if (hsaPlanProgram?.RawData?.Details?.ContributionType) {
      return hsaPlanProgram.RawData.Details.ContributionType?.includes('Employer Match');
    }
    console.log('used deprecated value for isEmployerMatch');
    return deprecatedIsEmployerMatch;
  }, [deprecatedIsEmployerMatch, hsaPlanProgram]);

  const Logo = useCallback(
    ({ className }) => {
      return relatedProduct?.ProviderID && relatedProduct?.ProviderID !== '00000000-0000-0000-0000-000000000000' ? (
        <div
          className={cx(
            className,
            css`
              border: 1px solid ${colors.gray[300]};
              border-radius: 8px;
              min-width: 164px;
              background-color: white;
              ${flex('center')}
            `
          )}
        >
          <CarrierLogo
            carrierID={relatedProduct?.ProviderID}
            planCarrierName={relatedProduct?.ProviderName}
            css={`
              min-width: 180px;
              max-width: 180px;
            `}
            maxHeight="80px"
            position="center"
          />
        </div>
      ) : null;
    },
    [relatedProduct?.ID]
  );

  return (
    <motion.div
      key={'hsaplan_question'}
      initial="pageInitial"
      animate="pageAnimate"
      exit="pageExit"
      variants={{
        pageInitial: {
          x: 400,
          opacity: 0,
        },
        pageAnimate: {
          x: 0,
          opacity: 1,
        },
        pageExit: {
          x: -400,
          opacity: 0,
        },
      }}
      className={benefitContainer}
      style={{
        border: `4px solid ${
          props.designStyles?.Theme?.Body?.ButtonColor ? props.designStyles?.Theme?.Body?.ButtonColor : '#25282D'
        }`,
      }}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          ${smallScreen} {
            .large-screen {
              display: none;
            }
          }
          ${largeScreen} {
            .small-screen {
              display: none;
            }
          }
        `}
      >
        <div
          className={css`
            display: grid;
            flex-grow: 1;
            margin-right: 16px;
            max-width: 66%;
            ${smallScreen} {
              min-width: 140px;
              max-width: 100%;
            }
          `}
        >
          <div
            className={css`
              display: grid;
              gap: 16px;
              padding: 16px;
            `}
          >
            <Text
              h4
              css={`
                font-size: 20px;
                padding-bottom: 8px;
                text-align: left;
              `}
            >
              Health Savings Account
            </Text>
            <Logo className="small-screen" />
            <Text
              css={`
                text-align: left;
              `}
            >
              Set aside money on a pre-tax basis to pay for qualified medical expenses.
            </Text>
          </div>
        </div>
        <Logo className="large-screen" />
      </div>

      <Modal display={learnMoreOpen} onClose={() => setLearnMoreOpen(false)}>
        <div
          className={css`
            padding: 24px;
          `}
        >
          <div
            className={css`
              ${flex('space-between')}
            `}
          >
            <Text h2>Health Savings Account</Text>
            <Button styles="icon" onClick={() => setLearnMoreOpen(false)}>
              <X />
            </Button>
          </div>
          <div
            className={css`
              max-height: 50vh;
              overflow-y: scroll;
              ${scrollbar.hide}
            `}
          >
            {isEmployerMatch && (
              <div
                className={css`
                  display: flex;
                  align-items: center;
                  margin: 8px auto;
                  flex-wrap: wrap;
                `}
              >
                <IdentificationCard
                  className={css`
                    margin-right: 24px;
                    ${smallScreen} {
                      display: none;
                    }
                  `}
                />
                <div>
                  <Typography>
                    Your employer provides an <strong>HSA match!</strong>
                  </Typography>
                  <Typography>
                    For every <strong>$1 you contribute</strong>, your{' '}
                    <strong>
                      employer will contribute $
                      {getEmployerMatchHSAValue(selectedRecommendation, hsaPlanProgram, isEmployerMatch)?.toFixed(2)}
                    </strong>
                  </Typography>
                  <Typography>
                    Up to{' '}
                    <strong>
                      ${getMaxHSAValue(selectedRecommendation, hsaPlanProgram, isEmployerMatch)?.toFixed(2)}
                    </strong>{' '}
                    monthly!
                  </Typography>
                </div>
              </div>
            )}

            <Typography style={{ marginTop: '16px' }}>
              Based off your responses from earlier, you may have some anticipated medical expenses this year. An HSA is
              a great way to plan for those costs and save for a rainy day!
            </Typography>

            <div className={listContainer}>
              <div className={itemContainer}>
                <Typography>
                  {isEmployerMatch
                    ? 'To cover estimated medical expenses, or to max out your employer match, contribute this much monthly to your HSA'
                    : 'Recommendation for monthly contribution to cover estimated remaining costs'}
                </Typography>
                <Typography style={{ fontWeight: 'bold' }}>
                  ${Math.floor(getCoverBillsValue(selectedRecommendation, hsaPlanProgram, isEmployerMatch))}
                </Typography>
              </div>
              <div className={itemContainer}>
                <Typography>Recommendation for monthly contribution to maximize your HSA</Typography>
                <Typography style={{ fontWeight: 'bold' }}>
                  ${getMaxMonthlyValue(selectedRecommendation, hsaPlanProgram, isEmployerMatch)}
                </Typography>
              </div>
            </div>

            <Typography style={{ fontWeight: 'bold', margin: '16px 0' }}>HSA Triple Tax Advantage</Typography>

            <div
              className={css`
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-wrap: wrap;
              `}
            >
              <div className={cardContainer}>
                <Receipt />
                <Typography>Contributions are Tax-Fee</Typography>
              </div>

              <div className={cardContainer}>
                <ChartLineUp />
                <Typography>Funds grow Interest-Free</Typography>
              </div>

              <div className={cardContainer}>
                <CreditCard />
                <Typography>Pay for Expenses Tax-Free</Typography>
              </div>
            </div>

            <Typography style={{ fontWeight: 'bold', margin: '16px 0' }}>HSA Useful Tips!</Typography>

            <div className={cardContainer}>
              <Vault />
              <Typography>The money is yours, even if you change employers!</Typography>
            </div>

            <div className={cardContainer}>
              <CreditCard />
              <Typography>Use it to pay for qualified Medical, Dental and Vision expenses tax-free!</Typography>
            </div>

            <div className={cardContainer}>
              <HourglassSimpleMedium />
              <Typography>An HSA compliments a 401(k) to bolster your retirement savings.</Typography>
            </div>

            <div className={cardContainer}>
              <CurrencyCircleDollar />
              <Typography>
                Any employer contribution is <strong>FREE MONEY!</strong>
              </Typography>
            </div>

            {relatedProduct?.Details?.MinimumBalanceForInvesting &&
            relatedProduct?.Details?.MinimumBalanceForInvesting !== '0' ? (
              <div className={cardContainer}>
                <ChartLineUp />
                <Typography>
                  Once you have over ${relatedProduct?.Details?.MinimumBalanceForInvesting} in your HSA, you can invest
                  for greater savings.
                </Typography>
              </div>
            ) : null}

            <div className={cardContainer}>
              <Receipt />
              <Typography>Any capital gains your HSA earns are tax-free.</Typography>
            </div>

            {!!titleDescriptions.length && (
              <div className={listContainer}>
                {titleDescriptions.map((item) => (
                  <div className={itemContainer} key={item.Title + item.Description}>
                    <Typography>{item.Title}</Typography>
                    <Typography style={{ fontWeight: 'bold' }}>{item.Description}</Typography>
                  </div>
                ))}
              </div>
            )}

            {props.benefit.Disclaimer && (
              <div
                className={css`
                  font-size: 9px;
                  font-weight: 400;
                  margin: 16px;
                `}
              >
                {parseDisclaimer(props.benefit.Disclaimer)}
              </div>
            )}
          </div>
        </div>
      </Modal>

      <div
        className={css`
          display: flex;
          justify-content: left;
        `}
      >
        <Button
          naked
          css={`
            text-decoration: underline;
            text-transform: none;
            padding: 8px 0;
            ${smallScreen} {
              padding: 8px 16px;
            }
          `}
          aria-label="Learn more"
          onClick={() => setLearnMoreOpen(!learnMoreOpen)}
          data-ph-capture-attribute-source={posthogAttrs?.types?.RECOMMENDATIONS}
          data-ph-capture-attribute-action={posthogAttrs?.actions?.VIEW_MORE}
          data-ph-capture-attribute-product-type={props.benefit?.Type}
        >
          Learn More
        </Button>
      </div>
    </motion.div>
  );
};
