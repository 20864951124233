import { MenuItem } from '@material-ui/core';
import { css } from 'emotion';
import { CaretDown, CreditCard } from 'phosphor-react';
import { useEffect, useRef, useState, useMemo } from 'react';
import { Button, Div, DropMenu, Text } from '../../../../../shared-components';
import { container, flex } from '../../../../../shared-components/shared-styles';
import { colors } from '../../../../../shared-components/styles';
import { useStore } from '../../../../store-provider/use-store';
import { useAxios } from '../../../use-axios';
import { currencyFormatterCents } from '../constants';
import { getProductPremium } from '../field-utils';
import { getTiersAndLabels } from '../plan-comparisons/use-display-settings';
import { PRODUCT_HEADERS } from '../product-comparisons';
import { CostACA } from '../cost-aca';
import { getAttributes } from '../../../utils';

const toTitleCase = (value) => {
  return (value || '')
    .split(' ')
    ?.map((word) => {
      const char = word.at(0)?.toUpperCase();
      return char + word.slice(1);
    })
    .join(' ');
};

const displayIntervals = {
  PPP: 'Pay Period',
  MO: 'Monthly',
};

const intervalLabels = {
  52: 'wk',
  24: 'semi-monthly',
  26: 'bi-weekly',
  12: 'mo',
};

const intervalLongLabels = {
  52: 'Weekly',
  24: 'Semi-Monthly',
  26: 'Bi-Weekly',
  12: 'Monthly',
};

export const PricingSummary = (props) => {
  const { type, data, summary, premiumsSetExternally = false, isPDF = false, column = {} } = props;
  const { id } = summary;
  const {
    product,
    meta: { networks },
  } = data;

  const {
    data: { displaySettings = {}, businessId },
  } = useStore();

  const containerRef = useRef();
  const [width, setWidth] = useState(700);
  const isProgramSummary = type === 'hsa_buyup';

  const programQuery = useAxios({
    url: `public/v1/business/${businessId}/programs`,
    enabled: product?.ID && isProgramSummary,
  });

  const program = useMemo(() => {
    return (
      programQuery?.data?.filter(
        ({ ProgramType, PlanID }) => ProgramType === 'health_savings_account' && PlanID === id
      )?.[0] || {}
    );
  }, [programQuery?.data?.length, product, isProgramSummary]);

  const componentAttributes = getAttributes({
    column,
    type: 'component',
  });

  const gatheringAcaInfoState = useState(false);
  const [tab, setTab] = useState('interval');
  const coreData = product?.Type === 'insurance_plan' ? networks.core : product;
  const productName = coreData?.ProductName || `${PRODUCT_HEADERS?.[product?.Type] || ''}`;

  const isEmployerMatch = isProgramSummary && program?.RawData?.Details?.ContributionType === 'HSA - Employer Match';
  const widgetDescription = isProgramSummary
    ? isEmployerMatch
      ? 'Employer Match'
      : 'Employer Contributions'
    : 'Pricing';

  const adjustedInterval = isEmployerMatch ? 12 : displaySettings?.premiumInterval;
  const tabInterval = tab === 'interval' ? adjustedInterval : 1;
  const premiumIntervalDisplay = intervalLongLabels[adjustedInterval];
  const intervalDisplay = tabInterval === 1 ? 'Yearly' : premiumIntervalDisplay;

  useEffect(() => {
    setWidth(containerRef?.current?.clientWidth || 0);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', (e) => setWidth(containerRef?.current?.clientWidth || 0));
    return () => window.removeEventListener('resize', (e) => setWidth(containerRef?.current?.clientWidth || 0));
  }, []);

  const pricingProps = {
    ...props,
    program,
    isEmployerMatch,
    isProgramSummary,
    intervalDisplay,
    tabInterval,
    width,
    displaySettings,
  };

  return (
    <Div
      ref={containerRef}
      css={css`
        ${container.box}
        width: 100%;
        padding: 24px;
        box-sizing: border-box;
      `}
      {...componentAttributes}
    >
      <Div
        css={css`
          ${flex('space-between')}
          box-sizing: border-box;
          gap: 16px;
        `}
      >
        <Text
          ellipsis
          css={`
            width: max-content;
            border-radius: 30px;
            background-color: var(--accent-color-4);
            color: var(--accent-color-0);
            padding: 4px 16px;
            font-weight: bold;
          `}
        >
          {widgetDescription}
        </Text>
        {/* Don't render interval dropdown for PDF view. */}
        {!isPDF && (
          <DropMenu
            button={
              <Button
                secondary
                css={`
                  text-transform: none;
                  min-width: 120px;
                  padding: 4px 8px;
                `}
              >
                {tab === 'interval' ? toTitleCase(intervalLongLabels[adjustedInterval]) : 'Yearly'}
                <CaretDown size={24} />
              </Button>
            }
          >
            {tab === 'interval' ? (
              <MenuItem onClick={() => setTab('yearly')}>Yearly</MenuItem>
            ) : (
              <MenuItem onClick={() => setTab('interval')}>{intervalLongLabels[adjustedInterval]}</MenuItem>
            )}
          </DropMenu>
        )}
      </Div>
      <Div
        css={css`
          ${flex('left')}
        `}
      >
        {isProgramSummary ? (
          <CreditCard
            className={css`
              min-width: 32px;
              margin-right: 16px;
            `}
          />
        ) : null}
        <Text
          h2
          css={`
            color: ${colors.black};
            margin: 16px 0;
          `}
        >
          {isProgramSummary ? `HSA - ` : ''}
          {productName}
        </Text>
      </Div>

      {premiumsSetExternally ? (
        <CostACA data={data} props={pricingProps} Summary={Pricing} gatheringAcaInfoState={gatheringAcaInfoState} />
      ) : (
        <Pricing {...pricingProps} />
      )}
    </Div>
  );
};

export const Pricing = ({
  intervalDisplay,
  tabInterval,
  isEmployerMatch,
  isProgramSummary,
  program,
  width,
  displaySettings,
  //
  data,
  summary,
}) => {
  const { product } = data;
  const { variantStyle = '' } = summary;

  const {
    data: { decisionTool = '' },
    setStore,
  } = useStore();
  const toggleDecisionTool = () => setStore('decisionTool', decisionTool ? '' : 'aca');

  const getPrice = (key) => {
    if (!isProgramSummary) {
      return getProductPremium({
        cost: product?.Cost,
        key,
        interval: tabInterval,
      });
    } else if (!isEmployerMatch) {
      let contribution = program?.RawData?.Details?.[`${key}`] || 0;
      contribution *= 12 / tabInterval;
      return currencyFormatterCents.format(contribution);
    } else if (isEmployerMatch) {
      let contribution = program?.RawData?.Details?.[`${key}EmployerMonthlyMatchMax`] || 0;
      contribution *= 12 / tabInterval;
      return currencyFormatterCents.format(contribution);
    }
  };

  const { sortedTiers, sortedTierLabels, tiers } = getTiersAndLabels({
    cost: product?.Cost,
    displaySettings,
    sortTierLabelsByRelevant: true,
  });

  const wrapPrice = isEmployerMatch ? '' : flex('space-between');
  const upToModifier = isEmployerMatch ? 'Up To ' : '';

  const HSAHelperText = () => {
    if (isEmployerMatch) {
      let contributions = {};
      tiers?.map((key) => {
        const contribution = program?.RawData?.Details?.[`${key}EmployerMonthlyMatch`] || 0;
        contributions = {
          ...contributions,
          [contribution]: [...(contributions?.[contribution] || []), key],
        };
      });
      const values = Object.entries(contributions);
      if (values.length > 1) {
        return (
          <Div css={css`margin: 8px; 0;`}>
            {values.map(([contribution, keys]) => (
              <Div
                css={css`
                  margin-bottom: 8px;
                `}
              >
                <Text bold>{keys?.map((key) => displaySettings?.[key] || key).join(', ')}</Text>
                <Text>For every $1 you contribute your employer will match ${contribution}</Text>
              </Div>
            ))}
          </Div>
        );
      } else if (values.length === 1 && Number(values?.[0]?.[0] || 0) !== 1) {
        return (
          <Text
            css={`
              margin-top: 8px;
            `}
          >
            For every $1 you contribute your employer will match ${values?.[0]?.[0] || 0}
          </Text>
        );
      }
      return null;
    } else if (isProgramSummary) {
      return (
        <Text
          css={`
            margin: 8px 0;
          `}
        >
          A health savings account is a tax-advantaged medical savings account available to taxpayers in the United
          States who are enrolled in a high-deductible health plan.
        </Text>
      );
    }
    return null;
  };

  return (
    <>
      <Div
        css={css`
          ${variantStyle === 'clean' ? `border-top: 1px solid ${colors.gray[300]};` : ''}
          padding: 16px 0;
          padding-bottom: 0;
          box-sizing: border-box;
          > div {
            flex: 1;
          }
          .contribution-container {
            ${wrapPrice}
            box-sizing: border-box;
            padding: 16px 24px;
            border-radius: 8px;
            ${width < 500
              ? `
                min-width: 100%;
                margin-bottom: 8px;
              `
              : `
                min-width: calc(50% - 40px);
                margin-bottom: 8px;
              `}
          }
          .display-name {
            flex-grow: 1;
            padding-right: 16px;
          }
          .modifier {
            margin-right: 8px;
            font-size: 0.83em;
          }
          .interval {
            position: absolute;
            bottom: -1.2em;
            right: 0;
            font-size: 0.83em;
          }
        `}
      >
        {sortedTiers?.map((key, idx) => (
          <>
            {product?.Cost?.RelevantTier && key === product?.Cost?.RelevantTier ? (
              <div
                className={css`
                  ${flex('space-between')}
                `}
              >
                <Text
                  css={`
                    margin-bottom: 8px;
                  `}
                >
                  Your Pricing
                </Text>
                <Button
                  naked
                  css={`
                    text-transform: none;
                  `}
                  onClick={toggleDecisionTool}
                >
                  Edit
                </Button>
              </div>
            ) : idx === 1 && product?.Cost?.RelevantTier ? (
              <Text
                css={`
                  margin: 16px 0;
                `}
              >
                All Pricing
              </Text>
            ) : null}

            <Div key={key} className="contribution-container" css={getContributionStyle(variantStyle, idx)}>
              <Text label className="display-name">
                {sortedTierLabels?.[idx]}
              </Text>
              <Div
                css={css`
                  ${flex('right wrap')}
                  position: relative;
                  margin-bottom: 4px;
                `}
              >
                {upToModifier ? (
                  <Text subtitle className="modifier">
                    {upToModifier}
                  </Text>
                ) : null}
                <Text h2 className="price">
                  {getPrice(key)}
                </Text>
                <Text subtitle className="interval">
                  {toTitleCase(intervalDisplay)}
                </Text>
              </Div>
            </Div>
          </>
        ))}
      </Div>
      <HSAHelperText />
    </>
  );
};

export const getContributionStyle = (variantStyle, idx) => {
  if (!variantStyle || variantStyle === 'clean') {
    return css`
      border: 1px solid ${colors.gray[300]};
      .display-name {
        color: ${colors.black};
      }
      .modifier {
        color: ${colors.black};
      }
      .interval {
        color: ${colors.black};
      }
      .price {
        color: ${colors.black};
      }
    `;
  } else if (variantStyle === 'bold') {
    const color = idx > 2 ? 'var(--accent-color-0)' : 'white';
    return css`
      background-color: var(--accent-color-${idx});
      .display-name {
        color: ${color};
      }
      .modifier {
        color: ${color};
      }
      .interval {
        color: ${color};
      }
      .price {
        color: ${color};
      }
      position: relative;
    `;
  }
};
